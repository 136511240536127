<template>
  <div class="content-wrapper">
    <page-header screenName="Aderir Campanha" :linkItems="linkItems" />

    <section  class="p-3 card">
      <b-row v-if="loading">
        <b-col
            cols="12"
            class="text-center"
        >
          <b-spinner variant="custom" class="mb-1" ></b-spinner>
        </b-col>
      </b-row>

      <validation-observer ref="joinCampaign">
        <b-form v-if="!loading" @submit.prevent="formSubmit">
          <b-row class="align-items-center">
            <b-col md="6" lg="4">
              <b-form-group
                  label="Selecione uma Campanha vigente*"
                  label-for="campanha"
                  label-class="font_size_label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Campanha Vigente"
                    rules="required"
                >
                  <v-select
                      id="campaigns"
                      v-model="chooseCampaign"
                      :options="campaigns"
                      @input="getUnits()"
                      variant="custom"
                      item-text="campanha_descricao"
                      item-value="id_campanha"
                      placeholder="Selecione uma campanha"
                      label="campanha_descricao"
                  >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="4">
              <b-form-group
                  label="Escolha a Unidade Operacional*"
                  label-for="uo"
                  label-class="font_size_label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="Unidade Operacional"
                    rules="required"
                >
                  <v-select
                      id="units"
                      v-model="chooseUnit"
                      :options="units"
                      :disabled="!chooseCampaign"
                      variant="custom"
                      item-text="descricao"
                      item-value="id_unidade"
                      placeholder="Selecione uma unidade"
                      label="descricao_unidade"
                  >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                  </v-select>

                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="4">
              <b-button type="submit" variant="custom-blue" class="button-config">
                Avançar
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </section>
  </div>
</template>

<script>
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'
import { situacaoCampanha } from "@core/utils/enums";
import { BRow, BCol, BForm, BFormGroup, BButton, BSpinner, BFormSelect } from 'bootstrap-vue'
import { getCampaignsByStatus } from "@/views/custom-pages/adesao/config";
import {AdesaoReset} from "@/views/custom-pages/adesao/Purchase/adesaoHelpers.js";
import {tipoCampanhaEnum} from "@/enum/tipoCampanha";
export default {
  components: {
    PageHeader,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    BSpinner,
    BFormSelect,
  },

  mounted() {
    this.getCampaigns();
    AdesaoReset();
  },

  data() {
    return {
      linkItems: [
        {
          name: 'Adesão',
          routeName: ''
        },
        {
          name: 'Aderir Campanha',
          active: true
        }
      ],

      required,

      loading: false,

      campaigns: [],
      units: [],

      chooseCampaign: null,
      chooseUnit: null,
    }
  },

  methods: {
    async getCampaigns() {
      this.loading = true

      const params = {
        situacao: [situacaoCampanha.EM_ANDAMENTO],
        tipoCampanha: tipoCampanhaEnum.REGIONAL,
      }

      await getCampaignsByStatus(params)
        .then(response => {
          this.campaigns = response.data
        })

      this.loading = false
    },

    async getUnits() {
      if(this.chooseCampaign) {
        this.loading = true

        await this.$http.get(this.$api.unidadeCampanhaParameter(this.chooseCampaign.id_campanha))
            .then(response => {
              this.units = response.data
            })

        this.loading = false
      }
    },

    formSubmit() {
      this.$refs.joinCampaign.validate()
        .then(success => {
          if (success) {
            this.$store.commit("adesaoState/SET_CHOOSE_CAMPAIGN", this.chooseCampaign)
            this.$store.commit("adesaoState/SET_CHOOSE_UNIT", this.chooseUnit)

            this.$router.push({name: 'purchase'})
          }
        })
    },
  },

  watch: {
    chooseCampaign(value) {
      if(!value) {
        this.chooseUnit = null
      }
    }
  }
}
</script>

<style scoped>
@import "./style.scss";
</style>