export const tipoEmpresa = {
  INDUSTRIA: 1,
  SISTEMA_INDUSTRIA: 2,
  INDUSTRIA_ASSOCIADA: 3,
  NAO_INDUSTRIA: 4,
  EMPRESA_BASE_NACIONAL: 5
};

export const situacaoEmpresa = {
  COMPLETO: 'Completo',
  INCOMPLETO: 'Incompleto',
}