<template>
  <div>
    <div v-if="sesiAdmins">
      <sesi-area-join-campaign />
    </div>

    <div v-if="companyAdmin">
      <company-area-join-campaign />
    </div>
  </div>
</template>

<script>
import SesiAreaJoinCampaign from "@/views/custom-pages/adesao/JoinCampaign/SesiAreaJoinCampaign";
import CompanyAreaJoinCampaign from "@/views/custom-pages/adesao/JoinCampaign/CompanyAreaJoinCampaign";
import { actions, subjects } from "@/libs/acl/rules";

export default {
  created() {
    if(!this.sesiAdmins && !this.companyAdmin) {
      this.$router.push({ name: 'misc-not-authorized' })
    }
  },

  components: {
    SesiAreaJoinCampaign,
    CompanyAreaJoinCampaign,
  },

  computed: {
    sesiAdmins() {
      return this.$can(actions.VISUALIZAR, subjects.ADESAO_SESI_DR) ||
             this.$can(actions.VISUALIZAR, subjects.ADESAO_SESI_UO);
    },

    companyAdmin() {
      return this.$can(actions.VISUALIZAR, subjects.ADESAO_EMPRESA);
    }
  }
}
</script>
