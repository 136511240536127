export const situacaoCampanha = {
    RASCUNHO: 'Rascunho',
    AGENDADA: 'Agendada',
    EM_ANDAMENTO: 'Em andamento',
    CONCLUIDO: 'Concluída',
    CANCELADO: 'Cancelada',
};

export const situacaoPedido = {
    RASCUNHO: 'Rascunho',
    EFETIVADA: 'Efetivada',
    CANCELADA: 'Cancelada',
}